.Footer-container{
    position: relative;
    
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    height: 20rem;
   align-items: center;
}
.social-links{
    display: flex;
    gap: 4rem;
   

}
.social-links>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    justify-content: center;
}
.logo-f>img{
    width: 10rem;
}
.blur-f-1{
    bottom: 0;
 width: 26rem;
 filter: blur(200px);
 background: red;
 height: 12rem;
right: 15%;
}
.blur-f-2{
    bottom: 0;
 width: 26rem;
 filter: blur(200px);
 background: rgb(255, 115, 0);
 height: 12rem;
left: 15%;
}